import React from "react";

const Card = ({ title, description, imgUrl }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="font-bold text-left">{title}</div>
      <div className="lg:min-h-[110px] mt-5 text-left">{description}</div>
      <div className="mt-[30px] items-center mx-auto">
        <img src={imgUrl} className="max-w-[300px] 2xl:max-w-[400px] h-auto" />
      </div>
    </div>
  );
};

export default Card;
