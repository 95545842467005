import * as React from "react";

export const ChevronRight = (props) => {
  return (
    <svg
      width={8}
      height={14}
      viewBox="4 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.54 6.29L1.88.64A1 1 0 10.46 2.05l4.95 5L.46 12a1 1 0 000 1.41 1 1 0 00.71.3.999.999 0 00.71-.3l5.66-5.65a1 1 0 000-1.47z"
        fill={props.color}
      />
    </svg>
  );
};
