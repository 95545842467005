import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../helper/supabaseClient";
import { TelegramShareButton, WhatsappShareButton } from "react-share";
import { useMediaQuery } from "react-responsive";
import PhoneFloat from "../images/float.png";
import { WA } from "../images/wa";
import { Telegram } from "../images/telegram";
import { Copy } from "../images/copy";
import Party from "../images/party.png";

export default function Queue() {
  const { referral_code } = useParams();
  const [rank, setRank] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
                              .from("waitlists")
                              .select("*")
                              .neq('invited', true)
                              .order("refer_count", { ascending: false },)
                              .order( "joined_date", { ascending: true })
                              
      if(error) return alert(error.message);

      const position = data.findIndex((value)=>value.referral_code === referral_code) + 1;
      setRank(position);
    };
    fetchData();
  }, []);

  const url = `${window.location.origin}/${referral_code}`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: isMobile ? "flex-start" : "center",
        justifyContent: isMobile ? "flex-start" : "center",
        backgroundColor: "#EFF6FF",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingTop: "30px",
        }}
      >
        <img src={Party} style={{ width: "60px", height: "60px" }} />
        <div
          style={{
            fontSize: "32px",
            fontWeight: "bold",
            marginBottom: "15px",
            textAlign: "left",
          }}
        >
          Yes, you're on the waitlist!
        </div>
        <div style={{ textAlign: "left", fontSize: "18px" }}>
          Want to cut the line and get early access? Refer others and move up
          the list.{" "}
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            flexDirection: "row",
            display: "flex",
            marginTop: "15px",
            padding: "8px",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            className="button"
            onClick={() => {
              navigator.clipboard.writeText(url);
              alert("Link copied");
            }}
          >
            {url}
          </div>
          <div
            className="button"
            onClick={() => {
              navigator.clipboard.writeText(url);
              alert("Link copied");
            }}
          >
            <Copy />
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "20px" }}>
          <div
            style={{
              marginRight: "20px",
              backgroundColor: "#25D366",
              borderRadius: "10px",
            }}
          >
            <WhatsappShareButton
              url={url}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 22px 8px 14px",
              }}
            >
              <WA />
              <span
                style={{ fontWeight: "bold", color: "#fff", marginLeft: "8px" }}
              >
                WhatsApp
              </span>
            </WhatsappShareButton>
          </div>

          <div
            style={{
              marginRight: "20px",
              backgroundColor: "#229ED9",
              borderRadius: "10px",
            }}
          >
            <TelegramShareButton
              url={url}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 22px 8px 14px",
              }}
            >
              <Telegram />
              <span
                style={{ fontWeight: "bold", color: "#fff", marginLeft: "8px" }}
              >
                Telegram
              </span>
            </TelegramShareButton>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "30px",
          }}
        >
          <div>Your position in the queue:</div>
          <div style={{ fontSize: "30px", fontWeight: "bold" }}>{rank}</div>
        </div>
      </div>
      <img
        className="floating"
        src={PhoneFloat}
        style={{
          width: isMobile ? "100%" : "50%",
          height: isMobile ? "350px" : "auto",
          objectFit: "cover",
        }}
      />
    </div>
  );
}
