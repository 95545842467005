import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TelegramShareButton, WhatsappShareButton } from "react-share";
import AppIcon from "../images/AppIcon.png";
import PhoneFloat from "../images/float.png";
import { Copy } from "../images/copy";
import "../index.css";
import { WA } from "../images/wa";
import { Telegram } from "../images/telegram";

export default function Referral() {
  const params = useParams();
  const navigation = useNavigate();
  // const url = "https://synergy.com.sg/apps/staging";
  const url = `${window.location.origin}/${params?.referral_code}`;
  useEffect(() => {
    if (Object.keys(params).length === 0) {
      navigation("/");
    }
  }, []);

  const [width, setWindowWidth] = useState(0);
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: width > 500 ? "row" : "column",
        alignItems: width > 500 ? "center" : "flex-start",
        justifyContent: width > 500 ? "center" : "flex-start",
        backgroundColor: "#F2F2F2",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingTop: "30px",
          // alignItems: "flex-start",
        }}
      >
        {width < 501 && (
          <img
            src={AppIcon}
            style={{
              width: "100px",
              objectFit: "contain",
            }}
          />
        )}
        <div
          style={{
            fontSize: "32px",
            fontWeight: "bold",
            marginBottom: "15px",
            textAlign: "left",
          }}
        >
          Refer Fellow Synergizers
        </div>
        <div style={{ textAlign: "left", fontSize: "18px" }}>
          Share all new SYNERGY app with Synergizers today!
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            flexDirection: "row",
            display: "flex",
            marginTop: "15px",
            padding: "8px",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <div
            className="button"
            onClick={() => {
              // window.open(url);
              navigator.clipboard.writeText(url);
              alert("Link copied");
            }}
          >
            {url}
          </div>
          <div
            className="button"
            onClick={() => {
              navigator.clipboard.writeText(url);
              alert("Link copied");
            }}
          >
            <Copy />
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "20px" }}>
          <div
            style={{
              marginRight: "20px",
              backgroundColor: "#25D366",
              borderRadius: "10px",
            }}
          >
            <WhatsappShareButton
              url={url}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 22px 8px 14px",
              }}
            >
              <WA />
              <span
                style={{ fontWeight: "bold", color: "#fff", marginLeft: "8px" }}
              >
                WhatsApp
              </span>
            </WhatsappShareButton>
          </div>

          <div
            style={{
              marginRight: "20px",
              backgroundColor: "#229ED9",
              borderRadius: "10px",
            }}
          >
            <TelegramShareButton
              url={url}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 22px 8px 14px",
              }}
            >
              <Telegram />
              <span
                style={{ fontWeight: "bold", color: "#fff", marginLeft: "8px" }}
              >
                Telegram
              </span>
            </TelegramShareButton>
          </div>
        </div>
      </div>
      <img
        className="floating"
        src={PhoneFloat}
        style={{
          width: width > 425 ? "50%" : "100%",
          height: width > 425 ? "auto" : "350px",
          objectFit: "cover",
        }}
      />
    </div>
  );
}
