import * as React from "react";

export const ChevronLeft = (props) => {
  return (
    <svg
      width={8}
      height={14}
      viewBox="0 0 1 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.46 7.71l5.66 5.65a1.001 1.001 0 101.42-1.41l-4.95-5L7.54 2a1 1 0 000-1.41 1 1 0 00-.71-.3 1 1 0 00-.71.3L.46 6.24a1 1 0 000 1.47z"
        fill={props.color}
      />
    </svg>
  );
};
