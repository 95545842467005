import "./App.css";
import { Routes, Route } from "react-router-dom";
import Queue from "./pages/Queue";
import Home from "./pages/Home";
import Congrats from "./pages/Congrats";
import Referral from "./pages/Referral";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/queue" element={<Queue />} />
        <Route path="/queue/:referral_code" element={<Queue />} />
        <Route path="/:referral_code" element={<Home />} />
        <Route path="/congrats" element={<Congrats />} />
        <Route path="/referral/:referral_code" element={<Referral />} />
        <Route path="/referral" element={<Referral />} />
      </Routes>
    </div>
  );
}

export default App;
