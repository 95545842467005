import React from "react";
import Slider from "react-slick";

import Card from "./Card";

import Settings1 from "../../../../images/settings.png";
import Settings2 from "../../../../images/changepassword.png";
import Settings3 from "../../../../images/shake.png";
import { ChevronLeft } from "../../../../images/ChevronLeft";
import { ChevronRight } from "../../../../images/ChevronRight";

const Settings = ({ children }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ChevronLeft color={"#09101D"} />,
    nextArrow: <ChevronRight color={"#09101D"} />,
  };
  return (
    <div className="py-[40px] px-[20px] lg:px-[50px] bg-white rounded-[10px] mb-[70px]">
      <div className="font-bold text-[#16A34A] text-2xl text-left pb-[30px]">
        Settings
      </div>
      <div className="flex flex-col items-start text-left">
        <div className="hidden lg:grid grid-cols-1 lg:grid-cols-3 gap-[30px]">
          <Card
            title="Main page"
            description="Settings page is where you can expect to configure the mobile app
              such as appearance, permissions and security."
            imgUrl={Settings1}
          />
          <Card
            title="Change password"
            description="Now, changing your password has never been easier. With just a few
              taps, you can update your account's security and keep your
              personal information safe."
            imgUrl={Settings2}
          />
          <Card
            title="Shake to Report"
            description="Something wrong in the app? Shake your phone to send a report to
              our support team."
            imgUrl={Settings3}
          />
        </div>
      </div>
      <div className="lg:hidden">
        <Slider {...settings} className="dots pb-[30px]">
          <Card
            title="Main page"
            description="Settings page is where you can expect to configure the mobile app
              such as appearance, permissions and security."
            imgUrl={Settings1}
          />
          <Card
            title="Change password"
            description="Now, changing your password has never been easier. With just a few
              taps, you can update your account's security and keep your
              personal information safe."
            imgUrl={Settings2}
          />
          <Card
            title="Shake to Report"
            description="Something wrong in the app? Shake your phone to send a report to
              our support team."
            imgUrl={Settings3}
          />
        </Slider>
      </div>
    </div>
  );
};

export default Settings;
