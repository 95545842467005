import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Congrats from "../images/congrats.png";
import PhoneFloat from "../images/float.png";
import { useMediaQuery } from "react-responsive";
import { supabase } from "../helper/supabaseClient";

export default function Done() {
  const location = useLocation();
  const navigation = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [loading, setLoading] = useState(true);
  const isiPhone = navigator.userAgent.match(/iPhone/i);
  const isAndroid = navigator.userAgent.match(/Android/i);
  const isIpad2 = navigator.userAgent.match(/iPad/i);
  const isIpad =
    /Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1;

  useEffect(() => {
    if (!location?.state) {
      navigation("/");
    } else {
      setLoading(false);
    }
  }, []);
  const url = `${window.location.origin}`;

  if (loading) {
    return <div>Please wait..</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#EFF6FF",
        height: "100vh",
      }}
    >
      <img src={Congrats} style={{ width: "60px", height: "60px" }} />
      <div style={{ fontSize: "32px", fontWeight: "bold" }}>
        Congratulations!
      </div>
      <div style={{ marginTop: "20px", marginBottom: "40px" }}>
        You are invited to be a beta tester for the new app.
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            marginBottom: "20px",
            fontWeight: "bold",
          }}
        >
          Click on the link below to download the all new mobile app
        </div> */}
        <div
          style={{
            backgroundColor: "#2563EB",
            borderRadius: "10px",
            flexDirection: "row",
            display: "flex",
            padding: "10px 50px",
            // marginTop: "10px",
            color: "#fff",
          }}
          className="button"
          onClick={() => {
            if (isiPhone || isIpad || isIpad2) {
              window.open(
                "itms-services://?action=download-manifest&url=https://synergy.com.sg/apps/staging/download.plist"
              );
            } else if (isAndroid) {
              window.open("https://synergy.com.sg/apps/staging/download.apk");
            } else {
              alert("Please use mobile device to download");
              // window.open("https://synergy.com.sg/apps/staging", "_blank");
            }
          }}
        >
          Download {(isiPhone || isAndroid || isIpad || isIpad2) && "for"}{" "}
          {isIpad
            ? "iPad"
            : isIpad2
            ? "iPad"
            : isiPhone
            ? "iPhone"
            : isAndroid
            ? "Android"
            : "now"}
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <img src={PhoneFloat} style={{ width: isMobile ? "60%" : "40%" }} />
      </div>
    </div>
  );
}
