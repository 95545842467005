import React from "react";

import Slider from "react-slick";

import Dark1 from "../../../../images/dark1.png";
import Dark2 from "../../../../images/dark2.png";
import Dark3 from "../../../../images/dark3.png";
import { ChevronLeft } from "../../../../images/ChevronLeft";
import { ChevronRight } from "../../../../images/ChevronRight";

const DarkMode = () => {
  const settingsDark = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ChevronLeft color={"#fff"} />,
    nextArrow: <ChevronRight color={"#fff"} />,
  };

  return (
    <div className="bg-[#18181B] py-[40px] px-[20px] lg:px-[50px] rounded-[10px] text-white">
      <div className="font-bold text-2xl">Dark mode</div>
      <div className="mt-5">Customise the appearance to your own liking</div>
      <div className="hidden lg:flex w-full justify-around items-center">
        <img src={Dark1} className="max-w-[300px] 2xl:max-w-[400px] h-auto mt-[30px]" />
        <img src={Dark2} className="max-w-[300px] 2xl:max-w-[400px] h-auto mt-[30px]" />
        <img src={Dark3} className="max-w-[300px] 2xl:max-w-[400px] h-auto mt-[30px]" />
      </div>
      <div className="lg:hidden">
        <Slider {...settingsDark}>
          <img src={Dark1} className="max-w-[300px] 2xl:max-w-[400px] h-auto mt-[30px]" />
          <img src={Dark2} className="max-w-[300px] 2xl:max-w-[400px] h-auto mt-[30px]" />
          <img src={Dark3} className="max-w-[300px] 2xl:max-w-[400px] h-auto mt-[30px]" />
        </Slider>
      </div>
    </div>
  );
};

export default DarkMode;
