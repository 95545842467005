import React, { useEffect, useState } from "react";
import PhoneLeft from "../../images/phone left.png";
import PhoneRight from "../../images/phone right.png";
import AppIcon from "../../images/AppIcon.png";
import Submission from "../../images/submission.png";
import TrackerPNG from "../../images/tracker.png";
import Shortcut from "../../images/shortcut.png";
import About from "../../images/about.png";
import CPD from "../../images/cpd.png";
import Calendar1 from "../../images/calendar1.png";
import Calendar2 from "../../images/calendar2.png";
import Calendar3 from "../../images/calendar3.png";
import WidgetIOS from "../../images/widget ios.png";
import WidgetAndroid from "../../images/widget android.png";
import Room1 from "../../images/room1.png";
import Room2 from "../../images/room2.png";
import Provider from "../../images/provider.png";
import Menu from "../../images/menu.png";
import Notification from "../../images/notification.png";
import Promotion from "../../images/promotion.png";
import Submission2 from "../../images/report.png";
import Certification from "../../images/certification.png";
import ProviderBlue from "../../images/provider blue.png";
import AboutBlue from "../../images/about blue.png";
import CalendarBlue from "../../images/calendar blue.png";
import EventDetailBlue from "../../images/event detail blue.png";
import Commission from "../../images/commission.png";
import Personal from "../../images/personal.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeft } from "../../images/ChevronLeft";
import { ChevronRight } from "../../images/ChevronRight";
import FeatureCard from "./components/FeatureCard";
import Form from "./components/Form/Form";
import moment from "moment";

import SettingsSection from "./Section/Settings";
import DarkModeSection from "./Section/DarkMode";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: <ChevronLeft color={"#09101D"} />,
  nextArrow: <ChevronRight color={"#09101D"} />,
};

export default function Home() {
  const [isLaunchTime, setIsLaunchTime] = useState(true);

  // useEffect(()=>{
  //   const currentTime = moment().utcOffset('+08:00');
  //   const targetDateTime = moment('2023-06-22 17:30:00').utcOffset('+08:00');

  //   if (currentTime.isAfter(targetDateTime)) {
  //     setIsLaunchTime(true)
  //   } else {
  //     setIsLaunchTime(false)
  //   }

  // },[])
  
  return (
    <div className="bg-[#EFF4FF] flex flex-1 flex-col py-0 px-[10px] lg:px-[10%] h-full">
      {/* HEADER */}
      <div className="flex justify-center flex-col lg:flex-row lg:h-screen">
        <div className="flex items-start flex-col flex-1 text-left pt-[50px] pr-[20px] pb-0 pl-[20px] lg:py-0 lg:px-[60px] justify-center">
          <img src={AppIcon} className="w-[80px] object-contain"/>
          <h1 className="text-[32px] font-bold mt-[30px] max-w-[446px]">
            Introducing the new OneSynergy Mobile App
          </h1>
          {isLaunchTime && 
            <>
              <div className="text-lg mt-[10px]">
                Click on the link below to download the mobile app!
              </div>
              <div className="w-full mt-[30px]">
                <a
                  href="https://1.synergy.com.sg/mobile"
                  target="_blank"
                  className="bg-[#2563EB] button border-none text-sm w-full lg:w-[300px] flex rounded-[10px] justify-center text-white h-[40px] items-center font-bold"
                >
                  Download the App
                </a>
              </div>
            </>
          }
        </div>

        <div className="flex flex-1">
          <div className="flex justify-center items-center w-1/2">
            <img src={PhoneLeft} className="w-full" />
          </div>
          <div className="flex justify-center items-center w-1/2">
            <img src={PhoneRight} className="w-full" />
          </div>
        </div>
      </div>
      {/* ACCESSIBLE */}
      <div className="flex flex-1 flex-col">
        <div>OneSynergy Mobile app</div>
        <div className="text-2xl font-bold mt-[10px] mb-[30px]">
          Easily accessible, at your fingertips.
        </div>

        <div className="flex flex-wrap items-center justify-center gap-5 mb-10">
          <div className="bg-[#E11D48] text-white font-bold text-[13px] py-1 px-4 rounded-[50px]">
            Easy accessibility
          </div>
          <div className="bg-[#F59E0B] text-white font-bold text-[13px] py-1 px-4 rounded-[50px]">
            User-friendly
          </div>
          <div className="bg-[#84CC16] text-white font-bold text-[13px] py-1 px-4 rounded-[50px]">
            Sleek and minimalistic design
          </div>
          <div className="bg-[#7C3AED] text-white font-bold text-[13px] py-1 px-4 rounded-[50px]">
            Optimal performance
          </div>
          <div className="bg-[#0EA5E9] text-white font-bold text-[13px] py-1 px-4 rounded-[50px]">
            Greater extent of capabilities
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center gap-[25px]">
          <img className="max-w-[300px] 2xl:max-w-[400px] w-full h-auto mx-auto" src={CalendarBlue} />
          <img className="max-w-[300px] 2xl:max-w-[400px] w-full h-auto mx-auto" src={EventDetailBlue} />
          <img className="max-w-[300px] 2xl:max-w-[400px] w-full h-auto mx-auto" src={AboutBlue} />
          <img className="max-w-[300px] 2xl:max-w-[400px] w-full h-auto mx-auto" src={ProviderBlue} />
        </div>
      </div>
      {/* HOME SCREEN */}
      <div className="bg-white py-[40px] px-[20px] lg:py-[40px] lg:px-[50px] rounded-[10px] mt-16">
        <div className="flex font-bold text-[#7C3AED] text-2xl">Dashboard</div>
        <div className="flex text-start flex-col lg:flex-row">
          <div className="flex flex-1 flex-col lg:mr-[20px] mt-[30px]">
            <div className="font-bold">Commission </div>
            <div className="flex mt-[10px]">
              We display your commission data on the home screen for your easy
              access. You can also choose to hide the data for privacy reasons.
            </div>
          </div>
          <div className="flex flex-1 flex-col lg:ml-[20px] mt-[30px]">
            <div className="font-bold" >
              Submission
            </div>
            <div className="flex mt-[10px]">
              Track your submissions conveniently on your mobile phone on the
              go. Stay informed of your latest submission progress so you can
              always have your eyes on your submission targets!
            </div>
          </div>
        </div>
        <div className="flex text-start flex-col lg:flex-row">
          <div className="flex flex-1 flex-col lg:mr-[20px] mt-[30px]">
            <img src={Commission} className="max-w-[400px] w-full h-auto self-center" />
          </div>
          <div className="flex flex-1 flex-col lg:mr-[20px] mt-[30px]">
            <img src={Submission} className="max-w-[400px] w-full h-auto self-center" />
          </div>
        </div>
        <div className="flex text-start mt-[30px] flex-col lg:flex-row">
          <div className="flex flex-1 flex-col lg:mr-[20px]">
            <div className="font-bold">Performance</div>
            <div className="font-bold text-[26px] my-5 mx-0">
              Keep track of your performance
            </div>
            <div className="flex mt-[10px]">
              Keep track of your NBC count with our new performance widgets. We
              let you know how much more NBC is needed to get you to your next
              designation and how much NBC you have earned. We also help you
              keep track of your monthly submission streaks so you will continue
              to stay motivated to scale new heights!
            </div>
            <img src={TrackerPNG} className="max-w-[400px] w-full h-auto mt-[30px] self-center" />
          </div>
          <div className="relative flex flex-1 flex-col mt-[60px] lg:mt-0 lg:ml-5">
            <div className="font-bold">Shortcuts</div>
            <div className="font-bold text-[26px] my-5 mx-0">
              Reorder and customise your shortcuts
            </div>
            <div className="flex mt-[10px]">
              Customise your home page with your own personalised menu items.
            </div>
            <img
              src={Shortcut}
              className="lg:absolute lg:-bottom-[40px] w-full 2xl:h-[500px] 2xl:pt-4 2xl:w-auto lg:left-1/2 lg:-translate-x-1/2 -mb-[40px] lg:mb-0 max-w-[300px] mt-[30px] mx-auto 2xl:max-w-[400px]"
            />
          </div>
        </div>
      </div>
      {/* ABOUT SYNERGY */}
      <div className="mt-[30px] flex flex-col lg:flex-row gap-[30px] lg:items-stretch">
        <div className="bg-white rounded-[10px] flex-1 flex py-[40px] px-[20px] lg:px-[50px] flex-col text-left">
          <div className="">
            <h3 className="font-bold text-2xl text-[#7C3AED]">About SYNERGY</h3>
            <div className="font-bold mt-5">Chatbot</div>
            <div className="mt-5">
              Learn more about SYNERGY in an all new interactive bot experience.
              Press any of the buttons and let our SYNERGY bot tell you what you
              need to know.
            </div>
          </div>
          <div className="flex flex-col justify-center mt-[20px]">
            <img src={AboutBlue} className="w-full max-w-[300px] 2xl:max-w-[400px] h-auto mx-auto" />
          </div>
        </div>
        <div className="bg-white rounded-[10px] flex-1 flex py-[40px] px-[20px] lg:px-[50px] flex-col justify-between text-left">
          <div className="">
            <h3 className="font-bold text-2xl text-[#059669]">CPD Hours</h3>
            <div className="font-bold mt-5">
              Redesigned
            </div>
            <div className="mt-5">
              View CPD hours on your mobile app so you will never miss out on
              any deadline.
            </div>
          </div>
          <div className="flex justify-center mt-[20px]">
            <img src={CPD} className="w-full max-w-[300px] 2xl:max-w-[400px] h-auto mx-auto" />
          </div>
        </div>
      </div>
      {/* CALENDAR */}
      <div className="bg-white py-[40px] px-[20px] lg:px-[50px] rounded-[10px] mt-[30px]">
        <div className="font-bold text-[#65A30D] text-2xl">Calendar</div>
        <div className="font-bold mt-5">
          Redesigned calendar with more features
        </div>
        <div className="mt-5">
          The all new calendar has more features such as filter by categories
          and display more event information.
        </div>
        <div className="lg:hidden">
          <Slider {...settings} className="dots">
            <img src={Calendar1} className="max-w-[275px] h-auto mt-[30px]" />
            <img src={Calendar2} className="max-w-[275px] h-auto mt-[30px]" />
            <img src={Calendar3} className="max-w-[275px] h-auto mt-[30px]" />
          </Slider>
        </div>
        <div className="hidden lg:flex w-full justify-around items-center">
          <img src={Calendar1} className="w-[30%] mt-[30px]" />
          <img src={Calendar2} className="w-[30%] mt-[30px]" />
          <img src={Calendar3} className="w-[30%] mt-[30px]" />
        </div>
      </div>
      {/* WIDGET */}
      <div className="bg-white py-[40px] px-[20px] lg:px-[50px] rounded-[10px] mt-[30px]">
        <div className="flex flex-col items-start text-left" >
          <div className="font-bold text-[#E11D48] text-2xl" >
            Widget
          </div>
          <div className="font-bold mt-5">
            iOS and Android
          </div>
          <div className="mt-5">
            Introducing our all new iOS and Android widgets that you can use as
            shortcuts for our app. We are introducing four new shortcuts -
            vCard, QR Scanner, Room Booking, Autobot. Clicking on any of the
            widgets will lead you straight to your desired page without going
            through many layers in the app.
          </div>
        </div>
        <div className="lg:hidden">
          <Slider {...settings} className="dots">
            <img src={WidgetIOS} className="max-w-[300px] h-auto mt-[30px]" />
            <img src={WidgetAndroid} className="max-w-[300px] h-auto mt-[30px]"
            />
          </Slider>
        </div>
        <div className="hidden lg:flex justify-center">
          <img src={WidgetIOS} className="max-w-[300px] mt-[30px]" />
          <img src={WidgetAndroid} className="max-w-[300px] mt-[30px]" />
        </div>
      </div>
      {/* ROOM BOOKING PERSONAL PARTICULAR */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:flex-row lg:flex-wrap lg:gap-[30px]">
        <div
          // style={{ flex: 2 }}
          className="col-span-2 mt-[30px] bg-white rounded-[10px] flex flex-col justify-between py-[40px] px-[20px] lg:px-[50px]"
        >
          <div className="flex flex-col items-start text-left mb-[40px]">
            <div className="font-bold text-[#C026D3] text-2xl">
              Room Booking
            </div>
            <div className="mt-5">
              Book a room through the app easily. Simply choose the room and
              enter a name, date and time for your booking and you are good to
              go!
            </div>
          </div>
          <div className="lg:hidden">
            <Slider {...settings} className="dots">
              <img
                src={Room1}
                className="max-w-[300px] 2xl:max-w-[400px] w-full self-center mt-10"
              />
              <img
                src={Room2}
                className="max-w-[300px] 2xl:max-w-[400px] w-full self-center mt-10"
              />
            </Slider>
          </div>
          <div className="hidden lg:grid grid-cols-2 w-full">
            <img src={Room1} className="max-w-[300px] 2xl:max-w-[400px] w-full mx-auto" />
            <img src={Room2} className="max-w-[300px] 2xl:max-w-[400px] w-full mx-auto" />
          </div>
        </div>
        <div className="col-span-1 mt-[30px] bg-white rounded-[10px] flex flex-col items-start justify-between text-left flex-1 py-[40px] px-[20px] lg:px-0">
          <div className="lg:px-[50px]">
            <div className="font-bold text-[#2563EB] text-2xl">
              Personal Particulars
            </div>
            <div className="mt-5">
              This is where you can find all your personal details related to
              SYNERGY.
            </div>
          </div>
          <div className="flex items-center justify-center mt-[40px] w-full">
            <img src={Personal} className="max-w-[300px] 2xl:max-w-[400px] w-full " />
          </div>
        </div>
      </div>

      <section className="mt-[30px]">
        <DarkModeSection />
      </section>

      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[31px] mt-[31px]">
        <FeatureCard
          title="Provider Code"
          titleColor="#EA580C"
          description="We provide you with a list of provider code for your easy access
            right on your mobile phone."
          imgUrl={Provider}
        />

        <FeatureCard
          title="Menu"
          titleColor="#4F46E5"
          description="These are the current menu items, stay tuned for more menu items
          coming your way!"
          imgUrl={Menu}
        />

        <FeatureCard
          title="Notification"
          titleColor="#0284C7"
          description="Enable your notifications and be notified with your latest
          activities over here."
          imgUrl={Notification}
        />

        <FeatureCard
          title="Promotion & Transfer Histories"
          titleColor="#059669"
          description="View your promotions and transfers within SYNERGY overtime here."
          imgUrl={Promotion}
        />

        <FeatureCard
          title="Submission Report"
          titleColor="#DB2777"
          description="A summary of your submissions for the month can be found here."
          imgUrl={Submission2}
        />

        <FeatureCard
          title="Certification History"
          titleColor="#CA8A04"
          description="Get a full view of all your hard-earned certifications right here."
          imgUrl={Certification}
        />

        <div className="col-span-1 w-full lg:col-span-3">
          <SettingsSection />
        </div>
      </section>
    </div>
  );
}
