import * as React from "react";

export const WA = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.99 6.547c-.008-.43-.043-.858-.103-1.282a4.312 4.312 0 00-.363-1.09A3.852 3.852 0 0019.83 2.48a4.298 4.298 0 00-1.082-.362c-.428-.062-.86-.097-1.292-.105-.183-.007-.42-.01-.53-.01L7.077 2c-.11 0-.347.003-.53.01-.43.008-.858.043-1.282.103a4.312 4.312 0 00-1.09.363A3.854 3.854 0 002.48 4.17c-.168.344-.29.708-.362 1.083-.062.428-.097.86-.106 1.292-.006.183-.01.42-.01.53L2 16.923c0 .11.003.347.01.53.008.43.043.858.103 1.282.072.378.194.744.363 1.09.37.73.963 1.325 1.694 1.696.344.168.708.29 1.083.362.428.062.86.097 1.292.105.183.007.42.01.53.01l9.848.002c.11 0 .347-.003.53-.01.43-.008.858-.043 1.282-.103a4.317 4.317 0 001.09-.363 3.854 3.854 0 001.696-1.695c.168-.343.29-.707.362-1.082.062-.428.097-.86.105-1.292.007-.183.01-.42.01-.53L22 7.077c0-.11-.003-.347-.01-.53zm-9.773 12.41h-.003a7.127 7.127 0 01-3.407-.868l-3.78.991 1.012-3.693a7.13 7.13 0 116.178 3.57z"
        fill="#fff"
      />
      <path
        d="M12.219 5.9a5.926 5.926 0 00-5.022 9.076l.14.224-.598 2.186 2.243-.588.216.128c.913.541 1.955.826 3.016.826h.003A5.926 5.926 0 0012.219 5.9zm3.485 8.47a1.834 1.834 0 01-1.202.847 2.44 2.44 0 01-1.122-.07 10.261 10.261 0 01-1.016-.376 7.94 7.94 0 01-3.043-2.688 3.463 3.463 0 01-.727-1.843 1.997 1.997 0 01.624-1.485.655.655 0 01.475-.223c.118 0 .237.001.341.006.11.006.256-.041.4.306.15.356.506 1.233.55 1.322a.328.328 0 01.015.312 1.215 1.215 0 01-.178.297c-.09.104-.187.232-.268.312-.089.089-.182.185-.078.364.268.458.602.873.991 1.233.423.376.908.675 1.433.884.178.09.282.075.386-.044.104-.12.445-.52.564-.699.118-.178.237-.148.4-.089.164.06 1.04.49 1.218.58.178.089.297.133.341.208.049.286.013.58-.104.847z"
        fill="#fff"
      />
    </svg>
  );
};
