import React from "react";

const FeatureCard = ({ title, description, imgUrl, titleColor }) => {
  return (
    <div className="flex flex-1 flex-col w-full justify-between py-[40px] px-[20px] lg:px-0 bg-white rounded-[10px]">
      <div className="lg:px-[50px]">
        <div
          style={{ color: titleColor ? titleColor : "black" }}
          className={`font-bold text-left text-2xl`}
        >
          {title}
        </div>
      </div>
      <div className="mt-5 items-center">
        <div className="text-base text-left lg:px-[50px]">{description}</div>
        <img src={imgUrl} className="mt-[30px] max-w-[300px] 2xl:max-w-[400px] h-auto mx-auto" />
      </div>
    </div>
  );
};

export default FeatureCard;
